.App {
  /* overflow-x:hidden;  */
  text-align: center;
  display: flex;
  align-items: center;
  background: #ffffff;
 
}


.AppLandscape{
    display: none;  
    
}



@media(min-aspect-ratio:13/9){
 .App{
    display: none;
 } 
 

.AppLandscape{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding:12px;
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: #006aaf;
    color:#ffffff;

}

}