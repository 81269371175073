.Phonelist{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #ffffff;
    border-bottom: 1px solid #E5E5E5;
    padding:16px;
}

.ImageLink{
    position: relative;
    display: flex;
    flex-direction: column;

}

.TAndC{
    position: absolute;
    bottom: 10px;
    left:16px;
     margin:0;
    font-weight: 500;
    font-size: 12px;
    line-height: 19px;
    color:#0b5290;
    

}

.List{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    position: relative;
    /* height: 100vh; */
    position: relative;
    width:100%;
    /* overflow-y: hidden;
    overflow-x: hidden;  */
}

.ListEmpty{
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top:40px;
    font-size: 16px;
    line-height: 19px;
    margin: 0;
}

.UpperDivision{
    z-index:1;
    position: sticky;
    top: 0px;
    width:100%;
}

.Spacing{
    padding:8px;
    width:100%;
    box-sizing: border-box;
    background: #F7F7F7;
}



.PhoneListing{
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    margin-bottom: 70px;
    background: #FFFFFF;
    /* box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.12);    */
    /* display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center; */
}

.AddPerson{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width:100%;
    padding:8px 0px;
    background: #FFFFFF;
    border-bottom: 1px solid #E5E5E5;

}

.AddImage{
    padding:0 16px;
}

.AddPerson_text{
font-weight: 500;
font-size: 16px;
line-height: 19px;
display: flex;
align-items: center;

color: #009AE0;

}
.SelectedDivision{
display: flex;
align-items:center;
justify-content: space-between;
width: 100%;
background: #F7F7F7;

}

.SelectedDivision_text{
    font-size: 12px;
    line-height: 17px;
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.6);
    margin:0px;
    padding:8px 16px;
    height:40px;

}

/* 
.VerifyButtonDivision{
    background: #FFFFFF;
    box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.12);
} */

.VerifyButton{
width: 90%;
height: 44px;
background: #009AE0;
border-radius: 4px;
font-weight: 500;
font-size: 16px;
line-height: 24px;

display: flex;
align-items: center;
text-align: center;
justify-content: center;
color: #FFFFFF;
outline: none;
border: none;
}

.DisabledVerify{
    background: #dddddd;
    cursor: not-allowed;
    width: 90%;
    height: 44px;
    border-radius: 4px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: #FFFFFF;
    outline: none;
    border:none;
}

.ModalTitle{
    display: flex;
    justify-content: flex-start;
    padding: 0 16px 0px 16px;
    border-bottom: 0.5px solid #E7E7E7;
}



.SuccessModalOkButton {
  background: #009ae0;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.0350996);
  border-radius: 5px;
  width: 90%;
  height: 44px;
  outline:none;
  border:none;
}

.SuccessModalButtonText {
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 1px;
  color: #ffffff;
  margin:0;
}

.FieldHeading{
    font-weight: 500;
font-size: 14px;
line-height: 17px;
/* identical to box height */

display: flex;
align-items: center;

color: rgba(0, 0, 0, 0.87);
}

.FormDivision{
   padding: 16px;
    /* width: 100%; */
    display: flex;
    flex-direction: column;
}

.InputField{
    background: #FFFFFF;
border: 1px solid #E5E5E5;
box-sizing: border-box;
border-radius: 4px;
width: 100%;
height: 40px;
outline: none;
}

.Success{
    font-weight: 500;
font-size: 20px;
line-height: 24px;
/* identical to box height */

text-align: center;

color: rgba(0, 0, 0, 0.87);
}

.Message{
    font-size: 14px;
line-height: 20px;
/* identical to box height, or 143% */
margin-bottom: 48px;
text-align: center;

color: rgba(0, 0, 0, 0.6);
}



.Left{
     display: flex;
    
    flex-direction: column;
    justify-content: flex-start;
     padding:0 16px;

}

.Right{
    display: flex;
    justify-content: flex-end;
    padding:0 16px;
        
}

.Name{
    font-size: 16px;
    line-height: 19px;
    margin:0;
    display: flex;
    align-items: center;
    justify-content: flex-start;    
    color: rgba(0, 0, 0, 0.87);
}

.Number{
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: rgba(0, 0, 0, 0.6);
    margin: 4px 4px 4px 0;
}


.ButtonDivision{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 0;
    background: #FFFFFF;
    box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.12);
    padding:12px 24px;

}


.ModalTitle{
    display: flex;
    justify-content: flex-start;
    padding: 0 16px 0 16px;
    /* border-bottom: 0.5px solid #E7E7E7; */
}



.SuccessModalOkButton {
  background: #009ae0;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.0350996);
  border-radius: 5px;
  width: 100%;
  height: 48px;
  outline:none;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 1px;
  color: #ffffff;
  margin-top:24px;
  border:none;
}


.SuccessModalButtonText {
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 1px;
  color: #ffffff;
}

.SuccessModalOkButtonDisabled{
   background: #dddddd;;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.0350996);
  border-radius: 5px;
  width: 100%;
  height: 48px;
  outline:none;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 1px;
  color: #ffffff;
  margin-top:24px;
  border:none;

}



.ShareWhatsappModal{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.WhatsappAnchorTag{
    display: flex;
    color: #ffffff;
    text-decoration: none;
}

.WhatsappButton{
  
    background: #33CC66;
border-radius: 4px;
font-weight: 500;
font-size: 16px;
line-height: 22px;
display: flex;
align-items: center;
text-align: center;
justify-content: center;
width:90%;
color: #FFFFFF;
outline: none;
height: 44px;
border:none;
}

.Search{
  width:100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding:16px 0;
  position: relative;
  background: #FFFFFF;
  border-bottom: 1px solid #E5E5E5;
}

.SearchInput {
height: 36px;
width:90%;
font-size:14px;
padding-left:40px;
background: #ffffff;
border: 1px solid #E5E5E5;
box-sizing: border-box;
border-radius: 4px;
outline: none;  
}

.SearchInput ::placeholder{
color: #000000;
opacity: 0.6;
}

.SearchImage{
    position: absolute;
    left:35px;

}


.ErrorMessage{
        color: red;
    height: 16px;
    font-size: 12px;
    display: flex;
    padding-top: 4px;
}