.Checkbox{
   
}

input[type="checkbox"] {
   height: 24px;
   width:24px;
   outline: none;
   cursor: pointer;
   -webkit-appearance: none;
   background-color: #fafafa;
   border: 1px solid #cacece;
   box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
       inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
   padding: 6px;
   border-radius: 3px;
   display: inline-block;
   position: relative;
}
input[type="checkbox"]:active,
input[type="checkbox"]:checked:active {
   box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
       inset 0px 1px 3px rgba(0, 0, 0, 0.1);
}
input[type="checkbox"]:checked {
   background-color: #009ae0;
   border: 1px solid #009ae0;
   box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
       inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05),
       inset 15px 10px -12px rgba(255, 255, 255, 0.1);
   color: #99a1a7;
}
input[type="checkbox"]:checked:after {
   content: "\2714";
   /* font-size: 16px; */
   position: absolute;
   top: 2px;
   left: 4px;
   color: #fff;
}
input[type="checkbox"][disabled]{
   background-color: #fff;
   border-color: #e7e7e7;
}
input[type="checkbox"][disabled]:checked:after{
   color: #9d9d9d;
}