.Header{
    display: flex;
    padding:24px;
    flex-direction: column;
    font-size: 12px;
    line-height: 19px;
    margin: 0;
    align-items: center;
    color: rgba(0, 0, 0, 0.87);
}

.Content{
    /* padding-top:32px; */
}

.Heading{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width:100%;
}

table{
    width:100%;
    border:2px solid #635f5f;
}

.FirstPara{
    padding:24px 0;
    border-bottom: 2px solid #635f5f;
}

.Question{
    font-weight: bolder;
}

.SecondPara{
    padding:24px 0;
    border-top: 2px solid #635f5f;
    border-bottom: 2px solid #635f5f;

}